<template>
  <div>
    <!-- 报名审核弹出 -->
    <b-modal id="person" v-model="checDiv" :title="ksmc" size="xl" title-class="font-18" hide-footer>
      <div class="flexList ">
        <div class="blue-font">考生信息</div>
      </div>
      <Person :ksbmbh="ksbmbh" :bmh="bmh" :checkType="checkType" :type="type" @success="$emit('success')"/>
    </b-modal>
    <b-modal id="chooseSh" v-model="showItem" title="设置审核项" size="md" centered title-class="font-18" hide-footer>
      <div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox :label="item.label" v-for="(item,key) in allData" :key="key">{{item.label}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="text-center">
        <el-button type="primary" size="small" class="h30 mr-2">确定</el-button>
        <el-button type="info" size="small" class="h30" @click="showItem=false">取消</el-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Person from '@/views/pages/admin/enroll/person'
export default {
  name: "user-Info-check",
  components:{
    Person,
  },
  data(){
    return {
      checDiv: false,
      ksmc: "",
      showItem: false,
      bmh: "",
      ksbmbh: "",
      checkType: "",
      allData: "",
      checkList:[],
      type: 1,
    }
  },
  methods:{
    show(bmh, ksbmbh, ksmc, type, isDialog){
      this.checkType = type
      this.bmh = bmh
      this.ksbmbh = ksbmbh
      this.ksmc = ksmc
      if (isDialog){
        this.checDiv = true
      } else {
        window.open(
            `/checkNewWin?isPhoto=${true}&ksbmbh=${this.ksbmbh}&bmh=${this.bmh}&type=${this.type}&checkType=${this.checkType}`,
            '_blank')
      }
    },
    hide(){
      this.checDiv = false
    }
  }
}
</script>

<style scoped>

</style>