<template>
  <div>
    <table class="table light-table table-hover table-bordered table-scroll">
      <thead class="thead-light">
      <tr>
        <th style="width: 15%">考试名称</th>
        <th style="width: 5%">姓名</th>
        <th style="width: 15%">身份证件号</th>
        <th style="width: 5%">应缴费用</th>
        <th style="width: 5%">减免金额</th>
        <th >申请原因</th>
        <th style="width: 6%">申请时间</th>
        <th style="width: 6%">审核状态</th>
        <th style="width: 5%">审核人</th>
        <th style="width: 6%">处理时间</th>
        <th style="width: 5%">操作</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, i) in tablelist" :key="i">
        <td>{{ item.ksmc }}</td>
        <td><el-button type="text" class="font-blue">{{ item.xm }}</el-button></td>
        <td>{{ item.sfzjh }}</td>
        <td>{{ item.yjfje }} 元</td>
        <td>{{ item.jmje }} 元</td>
        <td>{{ item.jmyy }}</td>
        <td>{{ formatDate(item.createTime) }}</td>
        <td><handleStatus :shzt="item.shzt"></handleStatus></td>
        <td>{{ item.shrxm }}</td>
        <td>{{ formatDate(item.shsj) }}</td>
<!--        <td class="light-blue-bg">-->
<!--          <b-button  class="btn btn-secondary h30" v-if="item.shzt == 1 || item.shzt == 2" @click="$refs.handleModal.show(item.sid)">审核</b-button>-->
<!--          <b-button  class="btn btn-info h30" v-else-->
<!--                     @click="$refs.handleModal.show(item.sid)">审核</b-button>-->
<!--        </td>-->
        <td class="tab-icon light-blue-bg">
<!--          <i class="iconfont icon-md-input text-dark" v-if="item.shzt == 1 || item.shzt == 2"></i>-->
          <i class="iconfont icon-md-input text-info"
             @click="$refs.userInfoCheck.show(item.bmh, ksbmbh, ksmc, 'breaks', isDialog)"></i>
        </td>
      </tr>
      </tbody>
    </table>
    <UserInfoCheck ref="userInfoCheck" @success="$emit('success')"></UserInfoCheck>
  </div>
</template>

<script>
import handleStatus from "@/components/table/handleStatus";
import UserInfoCheck from '@/views/pages/user/components/user-Info-check.vue'
export default {
  name: "edu.vue",
  props: {
    ksbmbh: String,
    ksmc: String,
    tablelist: Array,
    isDialog: Boolean,
  },
  components:{
    UserInfoCheck,
    handleStatus
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
