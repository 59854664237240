<style scoped>
  .pay-money {
    height: unset;
    border: 1px solid #dfdfdf;
    /* border-right: 1px solid #dfdfdf; */
    margin-bottom: -1px;
    padding: 0 10px;
  }

  .msgName {
    width: 155px;
    min-width: 155px;
  }

  #bmzt .msgContent {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
  }

  .circle {
    position: relative;
    z-index: 50;
    transition: all 0.3s;
    cursor: pointer;
  }

  .msgLabel {
    cursor: pointer;
    height: 35px;
    line-height: 35px;
    font-size: 12px;
    user-select: none;
  }

  .diff-width {
    flex-wrap: wrap;
  }

  .outerCotent {
    border-bottom: 1px solid #dfdfdf;
  }

  .use-circle {
    user-select: none;
  }

  .circle::after {
    content: " ";
    border: 1px solid red;
    border-radius: 50% / 50%;
    position: absolute;
    left: -5px;
    top: -7px;
    z-index: 48;
    width: 120%;
    height: 30px;
    min-width: 20px;
  }

  .personPic {
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .personPic img {
    width: 120px;
    height: 160px;
  }

  .msgLabel.msgtextarea {
    height: 100px !important;
  }

  .mb-3 {
    margin-bottom: 0.5rem !important;
  }

  .mr-2 {
    top: 3px;
  }

  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
  }

  /deep/.el-input__icon {
    line-height: inherit;
  }

  .flag div {
    display: inline-block;
  }

  .msgLabel {
    width: 50%;
  }

  .table th {
    background-color: #f3f5fb;
  }
</style>

<script type="text/javascript">
  import {
    getUserBmk
  } from "@/api/examination_user.js";

  import {
    getRegisterUserInfo,
    checkRegisterUser,
    registerUserSubject,
  } from "@/api/admin/register/registerUser.js";
  import {
    getSysConfig
  } from "@/api/common/public.js";
  import ImageInfo from "@/components/user/register_user/img-info.vue";
  import examForm from "@/components/form/examForm.vue";
  import {
    getNowExamHandle
  } from "@/api/admin/exam/examRecord";
  import {
    getUserExamFree
  } from "@/api/examination_user";
  import breaksHandle from "@/views/pages/admin/components/breaks-handle.vue";
  import registerInfo from "@/components/examInfo/registerInfo";
  import freeExam from "@/components/examInfo/freeExam";
  import statusInfo from "@/components/examInfo/statusInfo";
  import zwInfo from "@/components/examInfo/zwInfo.vue";

  export default {
    components: {
      ImageInfo,
      examForm,
      breaksHandle,
      registerInfo,
      freeExam,
      statusInfo,
      zwInfo,
    },
    props: {
      ksbmbh: {
        type: String,
        default: "",
      },
      bmh: {
        type: String,
        default: "",
      },
      examInfo:Object,
      isPhoto: {
        type: Boolean,
        default: false,
      },
      type: {
        default: 1,
      },
      checkType: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        sdsj: 0,
        RegisterLockTimeMax: 10,
        errorInfoList: [],
        errorInfoLabelList: [],
        errorInfoStr: "",
        errorInfoDmStr: "",
        feeInfo: "",
        subjectList: null,
        feeNum: "",
        info: {},
        isOrg: false,
        dictList: [],
        fields: [{
            sfbt: true,
            zddm: "xm",
            zdmc: "姓名",
            data: "",
            zdfl: "1",
            txlx: "input",
            zdbl: "50",
            readOnly: true,
          },
          {
            sfbt: true,
            glzdb: true,
            zddm: ["xbm", "xb"],
            zdmc: "性别",
            zdbmc: "dict_xb",
            zdcd: 2,
            zdfl: "1",
            txlx: "radio",
            zdbl: 50,
            readOnly: true,
          },
          {
            zddm: ["sfzjlxm", "sfzjlxmc"],
            zdbmc: "dict_sfzjlx",
            glzdb: true,
            zdcd: 30,
            zdfl: "1",
            sfbt: true,
            zdmc: "证件类型",
            txlx: "select",
            zdbl: 50,
            readOnly: true,
          },
          {
            sfbt: true,
            zddm: "sfzjh",
            zdmc: "证件号码",
            data: "",
            zdfl: "1",
            txlx: "input",
            zdbl: "50",
            readOnly: true,
          },

          {
            zddm: "csrq",
            zdbmc: "",
            glzdb: false,
            zdcd: 10,
            zdfl: "1",
            sfbt: true,
            zdmc: "出生年月",
            txlx: "date",
            zdbl: 50,
            readOnly: true,
          },
          {
            glzdb: false,
            sfbt: true,
            txlx: "input",
            zdbl: 50,
            zdcd: 50,
            zddm: "jg",
            zdfl: "1",
            zdmc: "籍贯",
            readOnly: true,
          },
        ],
        examHandleInfo: {
          shlb: "",
        },
        msInfo: null,
        msLabel: [],
        zslist: [
          "资格证书名称",
          "资格证书编号",
          "证书专业名称",
          "证书取得时间",
          "证明电子材料",
        ],
        xllist: ["毕业院校", "毕业时间", "证书编号", "所学专业", "证明电子材料"],
        info1: {
          zgshzt: "",
          zpshzt: "",
        },
        zwInfo: {},
        zwInfoShow: false,

      };
    },
    methods: {
      errorItem(item) {
        let index = this.errorInfoList.indexOf(item.zddm);
        if (index > -1) {
          //删除圈圈时触发
          this.errorInfoList.splice(index, 1);
          this.errorInfoLabelList.splice(index, 1);
        } else {
          //添加圈圈时触发
          this.errorInfoList.push(item.zddm);
          this.errorInfoLabelList.push(item.zdmc);
        }

        this.info.ischeck = this.errorInfoList.length ? "0" : "1";
        // 重新获取错误字段
        this.errorInfoStr = "";
        this.errorInfoDmStr = "";
        this.errorInfoLabelList.forEach((k) => {
          if (k) {
            this.errorInfoStr += "、" + k.replaceAll(" ", "");
          }
        });
        this.errorInfoList.forEach((k) => {
          if (k) {
            this.errorInfoDmStr += "、" + k.replaceAll(" ", "");
          }
        });
        if (this.errorInfoStr.length > 0) {
          this.errorInfoStr = this.errorInfoStr.substring(
            1,
            this.errorInfoStr.length
          );
          this.errorInfoDmStr = this.errorInfoDmStr.substring(
            1,
            this.errorInfoDmStr.length
          );
          this.info.zgshsm = this.errorInfoStr + "不符合";
          this.info.zgshzt = 2;
        } else {
          this.info.zgshsm = "";
          this.info.zgshzt = "1";
        }
      },
      Itemclick(data) {
        this.zwInfo = data;
        this.zwInfoShow = true;
      },
      // 审核考生数据
      checkRegister(checkType) {
        let param = {
          bmh: this.bmh,
          checkType: checkType,
        };
        if (checkType == "photo") {
          param["zpshzt"] = this.info.zpshzt;
          param["zpshsm"] = this.info.zpshsm;
        }
        if (checkType == "education") {
          param["xlshzt"] = this.info.xlshzt;
          param["xlshsm"] = this.info.xlshsm;
        }
        if (checkType == "quality") {
          param["cwzd"] = this.errorInfoStr;
          param["cwzddm"] = this.errorInfoDmStr;
          param["zgshzt"] = this.info.zgshzt;
          param["zgshsm"] = this.info.zgshsm ? this.info.zgshsm : "";
          param["yjfje"] = Number(this.feeNum * 100);
          param["sfyxxg"] = this.info.sfyxxg != null;
          // 资格审核锁定
          if (this.info.zgshzt == 9) {
            param["sdjcsj"] = this.info.sdjcsj;
          } else {
            param["sdjcsj"] = "";
          }
          // 允许考生修改
          if (this.info.sfyxxg != null && this.info.sfyxxg) {
            param["sfyxxg"] = this.info.sfyxxg;
            param["yxxgsj"] = this.info.yxxgsj;
          } else {
            param["sfyxxg"] = this.info.sfyxxg;
            param["yxxgsj"] = "";
          }
        }
        if (checkType == "final") {
          param["shzt"] = this.info.shzt;
          param["shsm"] = this.info.shsm;
        }
        if (this.isOrg) {
          if (checkType == "quality") {
            param["zgshzt"] = this.info1.zgshzt;
          }
          if (checkType == "photo") {
            param["zpshzt"] = this.info1.zpshzt;
          }
        }
        if (
          checkType == "quality" &&
          param["zgshzt"] == 2 &&
          param["zgshsm"].length <= 0
        ) {
          this.$notify.warning("资格审核不同意，必须填写说明。");
          return;
        }
        checkRegisterUser(param).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "审核成功!",
            });
            this.$emit("success");
            this.registerUserInfo();
          }
        });
      },
      radiochange() {
        return
        let val = this.info1.zgshzt
        if (val == "1") {
          this.errorInfoList = [];
          this.errorInfoLabelList = [];
          this.errorItem({});
        }
      },
      registerUserInfo() {
        getRegisterUserInfo(this.bmh).then((res) => {
          if (res.status) {
            let data = res.data;
            getUserBmk({
              ksbmbh: this.ksbmbh,
              sfzjh: data.sfzjh,
            }).then((res) => {
              if (res.status) {
                let list = res.data[res.data.length - 1] || {};
                for (const key in list) {
                  if (!data[key]) {
                    data[key] = list[key];
                  }
                }
                this.info = data;
                this.info1.zgshzt = data.zgshzt;
                this.info1.zpshzt = data.zpshzt;
                this.errorInfoStr = this.info.cwzd || "";
                this.errorInfoDmStr = this.info.cwzddm || "";
                this.errorInfoLabelList = this.errorInfoStr.split(/[、，,;]/);
                this.errorInfoList = this.errorInfoDmStr.split(/[、，,;]/);
                // this.getRegisterUserSubject();
                this.$parent.ksmc = this.info.ksmc;
                setTimeout(()=>{
                  this.$emit('afterRender');  // 在组件渲染完成后触发afterRender事件
                },1800)
                this.$nextTick(() => {
                  this.$emit("printPDF");
                });
              }
            });
          }
        });
      },
      // 设置锁定时间
      setTime() {
        let curTime = new Date();
        let val = curTime.setHours(curTime.getHours() + this.sdsj);
        val = new Date(val);
        let yy = val.getFullYear();
        let mm = val.getMonth() + 1;
        let dd = val.getDate();
        let hh = val.getHours();
        let mf =
          val.getMinutes() < 10 ? "0" + val.getMinutes() : val.getMinutes();
        let ss =
          val.getSeconds() < 10 ? "0" + val.getSeconds() : val.getSeconds();
        this.info.sdjcsj =
          yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
        this.info.yxxgsj =
          yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      },
      initInfo() {
        this.registerUserInfo();
        let userInfo = JSON.parse(this.getStore("userInfo"));
        // 是否是机构审核人员
        if (
          userInfo.userType.substring(1, 2) == "1" ||
          userInfo.userType.substring(2, 3) == "1"
        ) {
          this.isOrg = true;
        } else if (
          userInfo.userType.substring(8, 9) == "1" ||
          userInfo.userType.substring(9, 10) == "1"
        ) {
          this.isOrg = false;
        } else {
          window.location.href = "/home";
        }
        if (this.isOrg) {
          getNowExamHandle().then((res) => {
            if (res.status) {
              this.examHandleInfo = res.data;
            }
          });
        }
        getSysConfig("RegisterLockTimeMax").then((res) => {
          if (res.status) {
            this.RegisterLockTimeMax = Number(res.data);
          }
        });
      }

    },
    mounted() {
      // this.initInfo()
    },
    computed: {
      hasLockPower() {
        return this.examHandleInfo.shlb.indexOf("6") != -1;
      },
      hasUpdatePower() {
        return this.examHandleInfo.shlb.indexOf("5") != -1;
      },
    },
    watch: {
      'info.zgshzt': {
        deep: true,
        handler(val) {
          if (val == '1') {
            this.errorInfoList = []
            this.errorInfoLabelList = []
            this.errorItem({})
          }
        }
      },
      bmh: {
        immediate: true,
        handler(val) {
          if (val) {
            this.initInfo()
          }
        }
      }
    },
  };
</script>
<template>
  <div class="p-3" style="background: #fff">
    <div class="kstitle">
      <div class="page-title-box form-red-title d-flex align-items-center justify-content-center">
        {{ info.dymc }}
      </div>
      <div class="d-flex justify-content-end">
        <span class="ml-3" style="font-size: 14px">
          报名时间：{{ info.createTime }}
        </span>
      </div>
    </div>

    <div class="show card pb-2">
      <div class="outerCotent form-sure jbxx" style="border-bottom: 0">
        <div class="pay-money d-flex align-items-center justify-content-between pt-3 pb-3 title"
          style="border-top: 1px solid #dfdfdf">
          <div>基本信息</div>
        </div>
        <div class="msgContent flexList fs-xs value">
          <examForm v-model="info" :bmh="info.bmh" :ksbmbh="this.ksbmbh" :readOnly="true" :kxgList="[]" :showType="'print'"
            :errorList="errorInfoList"></examForm>
        </div>
      </div>

      <div v-if="info.kmxzfs == 1" class="bkxx">
        <div class="pay-money pt-3 pb-3">
          报考信息
        </div>
        <div class="mt-3">
          <div class="outerCotent person-form">
            <registerInfo v-model="info"></registerInfo>
          </div>
        </div>
      </div>
      <div v-if="info.mssqzt != null && info.mssqzt != '-1'" class="msxx">
        <div class="pay-money pt-3 pb-3">
          免试信息
        </div>
        <div class=" outerCotent person-form">
          <freeExam v-model="info"></freeExam>
        </div>
      </div>

      <div class="bmzt">
        <div class="pay-money pt-3 pb-3 ">
          报名状态
        </div>
        <div id="bmzt">
          <statusInfo v-model="info" :examInfo="examInfo"></statusInfo>
        </div>
      </div>
    </div>

    <breaksHandle v-if="(checkType == 'breaks' || !isOrg) && info.jmzt && info.jmzt != -1" :bmh="bmh"
      :showCheck="info.sfkysh"></breaksHandle>
    <b-modal id="" v-model="zwInfoShow" centered size="lg" scrollable :title="'职位详情（职位信息仅供参考，请以公告为准！）'"
      title-class="font-18" hide-footer>
      <zwInfo :info="zwInfo"></zwInfo>
    </b-modal>
  </div>
</template>
