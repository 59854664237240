<template>
  <div style="position: relative;">
    <div class="table-responsive border" id="tableDiv" style="max-width: 100%; min-height: 200px;">
      <el-checkbox-group v-model="checkList">
        <table class="table light-table table-hover table-bordered table-scroll" id="tabDiv2">
          <thead class="thead-light">
            <tr>
              <th style="width: 50px">
                <el-checkbox :indeterminate="isIndeterminate" @change="checkAll" class="mr-1">
                </el-checkbox>序号
              </th>
              <th style="width: 120px;" v-if="kmxzfs ==2">报考单位</th>
              <th style="width: 200px;">{{ kmxzfs == 1?'报考专业':'报考职位'}}</th>
              <th style="width: 100px" v-if="kmxzfs == 1">档案号</th>
              <th style="width: 80px">姓名</th>
              <!-- <th style="width: 100px">出生年月 </th> -->
              <th style="width: 50px">性别 </th>
              <th style="width: 80px">报名来源</th>
              <th style="width: 80px" v-if="kmxzfs == 1">报考考区</th>
              <th style="width: 50px" v-if="kmxzfs == 1">级别 </th>
              <th style="width: 80px" v-if="kmxzfs == 1">专业</th>
              <th style="width: 120px">报名号</th>
              <!-- <th style="width: 180px">资格审核人账号</th> -->
              <th style="width: 180px">资格审核人姓名</th>
              <th style="width: 110px" v-for="obj in filedList" :key="obj.key" v-if="tableTool[obj.key]">
                {{obj.label}}
              </th>
              <th class="guding top-guding text-center" style="width: 43px;">缴费 </th>
              <th class="guding top-guding text-center" style="width: 43px;">资格 </th>
              <th class="guding top-guding text-center" style="width: 43px;">审核 </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in tablelist" :key="index">
              <td>
                <el-checkbox :label="item.bmh" @change="chooseList">{{index+1+(pageData.pageNum-1)*pageData.pageSize}}
                </el-checkbox>
              </td>
              <td v-if="kmxzfs == 2">{{ item.bkdwzy }}</td>
              <td>
                <div class="line1" :title="item.bkgwxk">{{ item.bkgwxk }}({{item.bkgwxkm}})</div>
              </td>
              <td v-if="kmxzfs == 1">{{item.dah}}</td>
              <td>
                <el-button type="text" size="mini" class="font-blue h30">
                  {{ item.xm }}
                </el-button>
              </td>
              <!-- <td>{{ item.csrq }}</td> -->
              <td>{{ item.xb }}</td>
              <td>{{item.bmly?item.bmly:'网站报名'}}</td>
              <td v-if="kmxzfs == 1">{{item.kqmc}}</td>
              <td v-if="kmxzfs == 1">{{item.bkjbmc}}</td>
              <td v-if="kmxzfs == 1">{{item.bkdwzy}}</td>
              <td>{{item.bmhDec}}</td>
              <!-- <td>{{item.zgshrzh}}</td> -->

              <td>{{item.zgshrxm}}</td>
              <td v-for="obj in filedList" :key="obj.key" v-if="tableTool[obj.key]">
                <div v-if="obj.key != 'zhbyxxmc'">{{ item[obj.key] }}</div>
                <div v-else class="line1" :title="[item.zhbyxxmc,item.zhbyzy]">
                  {{ item.zhbyxxmc }}{{ item.zhbyzy }}
                </div>
              </td>
              <td class="tab-icon  light-yellow-bg guding top-guding">
                <i v-if="item.jfzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success "></i>
                <i v-else-if="item.jfzt == 2" class="iconfont icon-icon_minus-circled text-danger "></i>
                <i v-else class="iconfont icon-icon_minus-circled"></i>
              </td>
              <td class="tab-icon light-yellow-bg guding top-guding">
                <span role="button" title="资格审核">
                   <i v-if="item.zgsdzt == 1" class="iconfont icon-a-md-lock_outlineCopy text-danger"></i>
                    <i v-else-if="item.zgshzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success"></i>
                    <i v-else-if="item.zgshzt == 2" class="iconfont icon-ze-clear text-danger"></i>
                    <i v-else class="iconfont icon-icon_minus-circled"></i>
                </span>
              </td>
              <td class="tab-icon light-pink-bg">
                <i class="iconfont icon-md-input text-dark"
                  @click="$refs.userInfoCheck.show(item.bmh, ksbmbh, ksmc, 'quality', isDialog)"
                  v-if="(item.zgshzt == 2 || item.zgshzt == 1)  && item.jfzt != 0 || item.zgshzt == 9"></i>
                <i class="iconfont icon-md-input text-info" v-else
                  @click="$refs.userInfoCheck.show(item.bmh, ksbmbh, ksmc, 'quality', isDialog)"></i>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="fixedRight" style="min-width: 88px;" :style="[{width:fixedData.length*43+'px'}]" v-if="tabDivShow">
          <table class="table light-table table-hover table-bordered table-scroll tableCopy" id="tabDiv2">
            <thead class="thead-light">
              <tr>
                <th style="width: 50px">
                  <el-checkbox :indeterminate="isIndeterminate" @change="checkAll" class="mr-1"></el-checkbox>序号
                </th>
                <th style="width: 120px;" v-if="kmxzfs ==2">报考单位</th>
                <th style="width: 200px;">{{ kmxzfs == 1?'报考专业':'报考职位'}}</th>
                <th style="width: 100px" v-if="kmxzfs == 1">档案号</th>
                <th style="width: 80px">姓名</th>
                <!-- <th style="width: 100px">出生年月 </th> -->
                <th style="width: 50px">性别 </th>
                <th style="width: 80px">报名来源</th>
                <th style="width: 80px" v-if="kmxzfs == 1">报考考区</th>
                <th style="width: 50px" v-if="kmxzfs == 1">级别 </th>
                <th style="width: 80px" v-if="kmxzfs == 1">专业</th>
                <th style="width: 120px">报名号</th>
                <!-- <th style="width: 180px">资格审核人账号</th> -->
                <th style="width: 180px">资格审核人姓名</th>
                <th style="width: 110px" v-for="obj in filedList" :key="obj.key" v-if="tableTool[obj.key]">
                  {{obj.label}}
                </th>
                <th class="guding top-guding text-center" style="width: 43px;">缴费 </th>
                <th class="guding top-guding text-center" style="width: 43px;">资格 </th>
                <th class="guding top-guding text-center" style="width: 43px;">审核 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in tablelist" :key="index">
                <td>
                  <el-checkbox :label="item.bmh" @change="chooseList">{{index+1+(pageData.pageNum-1)*pageData.pageSize}}
                  </el-checkbox>
                </td>
                <td v-if="kmxzfs == 2">{{ item.bkdwzy }}</td>
                <td>
                  <div class="line1" :title="item.bkgwxk">{{ item.bkgwxk }}({{item.bkgwxkm}})</div>
                </td>
                <td v-if="kmxzfs == 1">{{item.dah}}</td>
                <td>
                  <el-button type="text" size="mini" class="font-blue h30">
                    {{ item.xm }}
                  </el-button>
                </td>
                <!-- <td>{{ item.csrq }}</td> -->
                <td>{{ item.xb }}</td>
                <td>{{item.bmly?item.bmly:'网站报名'}}</td>

                <td v-if="kmxzfs == 1">{{item.kqmc}}</td>
                <td v-if="kmxzfs == 1">{{item.bkjbmc}}</td>
                <td v-if="kmxzfs == 1">{{item.bkdwzy}}</td>
                <td>{{item.bmhDec}}</td>
                <!-- <td>{{item.zgshrzh}}</td> -->

                <td>{{item.zgshrxm}}</td>
                <td v-for="obj in filedList" :key="obj.key" v-if="tableTool[obj.key]">
                  <div v-if="obj.key != 'zhbyxxmc'">{{ item[obj.key] }}</div>
                  <div v-else class="line1" :title="[item.zhbyxxmc,item.zhbyzy]">
                    {{ item.zhbyxxmc }}{{ item.zhbyzy }}
                  </div>
                </td>
                <td class="tab-icon  light-yellow-bg guding top-guding">
                  <i v-if="item.jfzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success "></i>
                  <i v-else-if="item.jfzt == 2" class="iconfont icon-icon_minus-circled text-danger "></i>
                  <i v-else class="iconfont icon-icon_minus-circled"></i>
                </td>
                <td class="tab-icon light-yellow-bg guding top-guding">
                  <span role="button" title="资格审核">
                    <i v-if="item.zgsdzt == 1" class="iconfont icon-a-md-lock_outlineCopy text-danger"></i>
                    <i v-else-if="item.zgshzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success"></i>
                    <i v-else-if="item.zgshzt == 2" class="iconfont icon-ze-clear text-danger"></i>
                    <i v-else class="iconfont icon-icon_minus-circled"></i>
                  </span>
                </td>
                <td class="tab-icon light-pink-bg">
                  <i class="iconfont icon-md-input text-dark"
                    @click="$refs.userInfoCheck.show(item.bmh, ksbmbh, ksmc, 'quality', isDialog)"
                    v-if="(item.zgshzt == 2 || item.zgshzt == 1)  && item.jfzt != 0 || item.zgshzt == 9"></i>
                  <i class="iconfont icon-md-input text-info" v-else
                    @click="$refs.userInfoCheck.show(item.bmh, ksbmbh, ksmc, 'quality', isDialog)"></i>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </el-checkbox-group>
    </div>
    <UserInfoCheck ref="userInfoCheck" @success="$emit('success')"></UserInfoCheck>
  </div>

</template>

<script>
  import UserInfoCheck from '../components/user-Info-check'
  export default {
    name: "qualification.vue",
    props: {
      kmxzfs: Number,
      ksbmbh: String,
      ksmc: String,
      tablelist: Array,
      isDialog: Boolean,
      tabDivShow: Number,
      tableTool: Object,
      fixedData: Array,
      filedList: Array,
      pageData: Object
    },
    components: {
      UserInfoCheck,
    },
    data() {
      return {
        isIndeterminate: false,
        checkList: [],
      }
    },
    methods: {
      // 全选
      checkAll(e) {
        this.checkList = e ? this.tablelist.map(item => {
            return item.bmh
          }) : [],
          this.isIndeterminate = e
        this.chooseList()
      },

      chooseList() {
        this.$emit("chooseList", this.checkList)
      }
    },
    mounted() {
      this.$emit("init")


    },
    watch: {
      kmxzfs: {
        immediate: true,
        handler(n, o) {
          if (n && !o) {
            this.kmxzfs = n
            // console.log(this.kmxzfs)
          }
        }
      },

    }

  }
</script>

<style scoped>

</style>
