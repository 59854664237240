<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/enroll-form.css";

  .modal-title /deep/ {
    text-align: center !important;
    width: 100% !important;
    padding: auto !important;
  }

  .el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px;
  }



  .guding.top-guding {
    width: 30px;
  }

  @media screen and (max-width: 576px) {
    .modal-dialog {
      margin: 0 auto !important;
    }
  }
</style>
<style scoped>
  /deep/.el-transfer-panel__list,
  /deep/.el-transfer-panel__body {
    height: 400px;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }
</style>
<script>
  import Layout from "../../layouts/main";
  import CheckHeader from "@/components/check-header";
  import changeExam from '@/components/change-exam.vue'
  import {
    getNowExam,
    getExamEasy
  } from "@/api/admin/exam/examRecord.js"
  import {
    handleList
  } from "@/api/admin/exam/checkPower";
  import qualificationVue from "./check-module/qualification";
  import imgVue from "./check-module/img";
  import eduVue from "./check-module/edu";
  import freeApply from "./check-module/free-apply";
  import checkQuery from "@/components/table/check-query.vue"
  import studentPrints from "@/components/print/studentPrints.vue"
  import {
    exportBmkFields,
    saveFileds
  } from '@/api/common/public'
  import $ from "jquery"


  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      changeExam,
      qualificationVue,
      imgVue,
      eduVue,
      freeApply,
      checkQuery,
      studentPrints
    },
    data() {
      return {
        title: "考试报名信息审核  /",
        title2: " ",
        subTitle: "切换报名",
        items: [{
            text: "用户首页"
          },
          {
            text: "控制面板",
            active: true
          }
        ],

        examParam: {
          ksnf: new Date().getFullYear(),
          ksmc: ''
        },
        choose: 1,
        ksbmbh: "",
        ksmc: "",
        tablelist: [],
        showmodal: false,
        tableTool: {
          mzmc: 1,
          zgxlmc: 1,
          zgxwmc: 1,
          zhbyxxmc: 1,
          sfzjlxmc: 0,
          sfzjh: 1,
          zzmmmc: 0,
          hyztmc: 0,
          jkzkmc: 0,
          gjdqmc: 0,
          hjszdmc: 0,
          jg: 0,
          gatqwmc: 0,
          xyzjmc: 0,
          yddh: 0,
          dzxx: 0,
        },
        examInfo: {},
        gudingFileds: [{
            label: "报考单位",
            key: "bkdwzy",
          },
          {
            label: "报考专业",
            key: "bkgwxk",
          },

          {
            label: "档案号",
            key: "dah",
          },
          {
            label: "报考时间",
            key: "create_time",
          },
          {
            label: "出生年月",
            key: "csrq",
          },
          {
            label: "身份证号",
            key: "sfzjh",
          },
          {
            label: "性别",
            key: "xb",
          },
          {
            label: "报考考区",
            key: "kqmc",
          },
          {
            label: "级别",
            key: "jbmc",
          },
          {
            label: "专业",
            key: "zymc",
          },
        ],
        allData: [],
        filedList: [],

        /* allData: [{
            label: "民族",
            key: "mzmc",
          },
          {
            label: "学位",
            key: "zgxwmc",
          },
          {
            label: "毕业学校",
            key: "zhbyxxmc",
          },
          {
            label: "证件类型",
            key: "sfzjlxmc",
          },
          {
            label: "证件号码",
            key: "sfzjh",
          },
          {
            label: "政治面貌",
            key: "zzmmmc",
          },
          {
            label: "婚姻状态",
            key: "hyztmc",
          },
          {
            label: "健康状态",
            key: "jkzkmc",
          },
          {
            label: "国家地区",
            key: "gjdqmc",
          },
          {
            label: "户籍所在地",
            key: "hjszdmc",
          },
          {
            label: "籍贯",
            key: "jg",
          },
          {
            label: "港澳台侨",
            key: "gatqwmc",
          },
          {
            label: "宗教信仰",
            key: "xyzjmc",
          },
          {
            label: "移动电话",
            key: "yddh",
          },
          {
            label: "电子邮件",
            key: "dzxx",
          },
          // {
          //   label: "照片",
          //   key: "zp",
          //   fixed: "right",
          // },

          // {
          //   label: "资格",
          //   key: "zg",
          //   fixed: "right",
          // },
          // {
          //   label: "审核",
          //   key: "sh",
          //   fixed: "right",
          // },
        ], */
        tabDivShow: 0,
        checkData: ['sfzjh', 'mzmc', 'zhbyxxmc'],
        pageData: {},
        module: null,
        moduleName: "BMZYB",
        kmxzfs: 0,
        search: [],
        isDialog: true,
        showDialog: false,
        checkList: [],
        printList: [],
        queryMore:{}
      };


    },
    methods: {
      // 表格全部字段
      getFileds() { // 获取表格字段
        let _this = this
        let list1 = []
        let list2 = []
        let newList = []
        let notShowFileds = ["照片存放地址", "照片审核状态", "照片审核说明", "照片审核人账号", "照片审核人", "照片审核时间", "照片审核IP", "审核错误字段"]
        _this.filedList = []
        if (this.ksbmbh != "") {
          exportBmkFields(this.ksbmbh).then(res => {
            if (res.status) {
              list1 = res.data.registerFields
              list2 = res.data.statusFields
              newList.push(...list1, ...list2)
              newList.forEach(item => {
                // if (!_this.gudingFileds.filter(val => {
                //     return val['key'] == item.zddm
                //   })[0]) {
                if (!notShowFileds.filter(val => {
                    return val == item.zdmc
                  })[0]) {
                  if (!item.zdmc.endsWith("码")) {
                    _this.filedList.push({
                      label: item.zdmc,
                      key: item.zddm,
                      detail: item,
                    });
                  }

                }
                if (!this.tableTool[item.zddm]) {
                  this.tableTool[item.zddm] = 0
                }
              })
              this.allData.push(...this.filedList)
            }
          })
        }


      },
      // 自定义显示的表格列数
      getTool(e) {

        let check = JSON.parse(JSON.stringify(this.checkData))
        let all = this.tableTool
        let newList = []
        let newObj = {
          zp: "zpshzt",
          xl: "xlshzt",
          zg: "zgshzt",
          jf: "jfzt",
          zt: 0,
          bj: 0,
          sc: 0,
          sh: 0,
          isSend: 0
        }
        for (var i in all) {
          if (check.indexOf(i) > -1) {
            this.tableTool[i] = 1
            if (newObj[i] != null) {
              if (newObj[i] != 0) {
                newList.push(newObj[i])
              }
            } else {
              newList.push(i)
            }

          } else {
            this.tableTool[i] = 0
          }
        }
        if (this.ksbmbh != "") {
          if (this.tableTool && !this.tableTool.isSend || e) {
            this.saveToBase(newList)
          }
        }

        // this.setStore("tableTool", this.tableTool)
        // 解决表格重叠的问题
        let tab = document.getElementById("tableDiv");
        this.$nextTick(() => {
          if (tab && tab.scrollWidth > tab.offsetWidth) {
            this.tabDivShow = 1
          } else {
            this.tabDivShow = 0
          }
        })
        this.showmodal = false
        this.$forceUpdate()
      },
      //获取切换的考试
      getLastExam() {
        getNowExam().then(res => {
          if (res.status) {
            this.ksbmbh = res.data.ksbmbh
            this.kmxzfs = res.data.kmxzfs
            this.ksmc = res.data.ksmc
            this.examInfo = res.data
            if (this.ksbmbh == '') {
              this.getExamList()
            }
            this.getList()
            this.getFileds()
          }
        })
      },
      // 获取考试列表
      getExamList() {
        getExamEasy(this.examParam).then(res => {
          if (res.status) {
            this.ksbmbh = res.data[0].ksbmbh
            this.ksmc = res.data[0].ksmc
            this.kmxzfs = res.data[0].kmxzfs
          }
        })
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val;
        this.getList(this.queryMore);
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        this.getList(this.queryMore);
      },
      //更多查询
      getListMore(more={}){
        this.queryMore=more?more:{}
        this.getList(more)
      },
      getList(more = {}) {
        this.pageData.ksbmbh = this.ksbmbh
        let search = Object.assign({}, more, this.pageData);

        this.module = this.$route.params.module
        handleList(this.module, search).then(res => {
          if (res.status) {
            this.tablelist = res.data
            this.pageData.total = res.total;
            this.pageData.pageNum = res.pageNum;
            this.pageData.pageSize = res.pageSize
            if (this.tablelist) {
              this.tablelist.forEach(k => {
                if (k.sqjmje) {
                  k.sqjmje = k.sqjmje / 100

                }
                if (k.sjfje) {
                  k.sjfje = k.sjfje / 100
                }
                if (k.yjfje) {
                  k.yjfje = k.yjfje / 100
                }
                if (k.jmje) {
                  k.jmje = k.jmje / 100
                }
                // 拿到数据后格式化时间
                this.$nextTick(() => {
                  for (let key in k) {
                    if (typeof(k[key]) === "string" && k[key].indexOf("T") == 10) {
                      k[key] = this.formatDateDetails(k[key])
                    }
                  }
                })
              })

            }

          }
        })


      },
      isDialogChange(e) {
        this.isDialog = e
      },
      getLocalTool() { // 获取表格自定义显示的localstore的数据
        let tool = this.getStore("tableTool")
        if (tool) {
          let newTool = JSON.parse(tool)
          if (newTool) {
            let box = []
            for (let i in newTool) {
              if (newTool[i] == 1) {
                box.push(i)
              }
            }
            this.checkData = [...box]
            this.tableTool = newTool
          }
        }
        this.getTool()
      },
      // 字段保存到数据库
      saveToBase(val) {

        let ksbmbh = this.ksbmbh
        if (ksbmbh != '') {
          let querykeys = val.join(",")

          saveFileds(ksbmbh, querykeys).then(res => {
            if (res.status) {
              this.tableTool.isSend = true
              this.setStore("tableTool", this.tableTool)

            }
            this.getList()
          })
        }

      },

      // 批量打印报名信息
      printAll() {
        let len = this.checkList.length
        if (len) {
          $('#pdf').css('display', '')
          this.printList = this.checkList
          let ids = [];
          this.printList.forEach((v) => {
            ids.push('pdf' + v + 1);
          });
          let str = ids.join(",");

          this.$confirm(`是否打印所选考生报考信息,共${len}条数据?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            $("#printId").css('display', '')
            this.printList.forEach((v) => {
              ids.push('pdf' + v + 1);
              this.toPdfHtml("printId", 'pdf' + v + 1, 'pdf' + v);
            });
            $('#pdf').css('display', 'none')

            this.$message({
              type: 'success',
              message: '数据生成中，请耐心等待'
            })
            this.ExportSavePdf("报名信息表", str, 0, true, "printId");
          }).catch(() => {
            $('#pdf').css('display', 'none')
            this.$message({
              type: 'info',
              message: '已取消打印'
            })
          })
        } else {
          this.$message({
            type: 'warning',
            message: '请选择要打印的数据！'
          })
        }

      },

      // 打印前的处理
      toPdfHtml(id, id1, id2) {
        let width = $("#" + id).outerWidth()
        this.pageHeight = Math.floor(width / 210 * 297)
        if ($("#" + id1).length > 0) {
          return false
        }
        this.pdfNum = 1;
        this.pdfHeight = 0;
        $("#" + id).append("<div id='" + id1 + "'></div>");
        $("#" + id1).append(
          "<div class='pdf' id='" +
          id1 +
          "1' page='1' style='height:" +
          this.pageHeight +
          "px'></div>"
        );
        //
        if ($("#" + id2 + " .kstitle").length > 0) {
          this.addItem($("#" + id2 + " .kstitle"), $("#" + id2 + " .kstitle").outerHeight(true), id1);
        }
        if ($("#" + id2 + " .jbxx").length > 0) {
          this.addItem($("#" + id2 + " .jbxx"), $("#" + id2 + " .jbxx").outerHeight(true), id1);
        }
        if ($("#" + id2 + " .bkxx").length > 0) {
          this.addItem($("#" + id2 + " .bkxx"), $("#" + id2 + " .bkxx").outerHeight(true), id1);
        }
        if ($("#" + id2 + " .msxx").length > 0) {
          this.addItem($("#" + id2 + " .msxx"), $("#" + id2 + " .msxx").outerHeight(true), id1);
        }
        if ($("#" + id2 + " .bmzt").length > 0) {
          this.addItem($("#" + id2 + " .bmzt"), $("#" + id2 + " .bmzt").outerHeight(true), id1);
        }
      },

      addItem(item, height, id, imgurl) {
        let paddingHeight = 60;
        height = Number(height);
        // 当前pdf高度大于0，且未添加新对象高度前后的页数不一致则换页
        if (
          this.pdfHeight > 0 &&
          parseInt((this.pdfHeight + paddingHeight) / this.pageHeight) !=
          parseInt((this.pdfHeight + height + paddingHeight) / this.pageHeight)
        ) {
          // pdf页数加1
          this.pdfNum += 1;
          this.pdfHeight = (this.pdfNum - 1) * this.pageHeight;
          $("#" + id).append(
            "<div class='pdf' id='" +
            id +
            this.pdfNum +
            "' page='" +
            this.pdfNum +
            "'style='height:" +
            this.pageHeight +
            "px'></div>"
          );
        }
        this.pdfHeight += height;
        // 将内容放入div中
        $(item)
          .clone()
          .appendTo($("#" + id + this.pdfNum));
        $("#" + id + " .pdf").css("padding", "30px 25px");
        $("#" + id + " .pdf").css("color", "black");
        if (imgurl) {
          $("#" + id + " .pdf").css("background-image", "url(" + imgurl + ")");
        }
      },
      chooseList(e) {
        this.checkList = e
      }
    },
    watch: {
      "$route.params.module": {
        // immediate: true,
        handler(e) {
          this.module = e;
          this.getList()
        }
      },
    },
    mounted() {
      this.getLastExam()
      this.getLocalTool()
    },
    computed: {
      fixedData() {
        let allData = JSON.parse(JSON.stringify(this.allData))
        let tableTool = JSON.parse(JSON.stringify(this.tableTool))
        return allData.filter(k => {
          return k.fixed == 'right' && tableTool[k.key]
        })
      }
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :subTitle="subTitle" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div style="margin-bottom: 10px">
              <checkQuery :kmxzfs="kmxzfs" v-model="pageData" @getList="getListMore" @isDialogChange="isDialogChange"
                :showDialog="showDialog" :ksbmbh="ksbmbh" :isOrg="true" :moduleNum="module" @printAll="printAll">
              </checkQuery>
            </div>
            <div>
              <qualificationVue v-if="module==1 && filedList.length>0" :ksbmbh="ksbmbh" :isDialog="isDialog"
                @success="getList" :ksmc="ksmc" :tabDivShow="tabDivShow" :kmxzfs="kmxzfs" :tableTool="tableTool"
                :fixedData="fixedData" :tablelist="tablelist" :filedList="filedList" :pageData="pageData"
                @init="getTool" @chooseList="chooseList" />

              <imgVue v-if="module==2&& filedList.length>0" :ksbmbh="ksbmbh" :kmxzfs="kmxzfs" :isDialog="isDialog"
                @success="getList" :ksmc="ksmc" :tabDivShow="tabDivShow" :tableTool="tableTool" :fixedData="fixedData"
                :tablelist="tablelist" :filedList="filedList" :pageData="pageData" @init="getTool"
                @chooseList="chooseList" />

              <eduVue v-if="module==3&& filedList.length>0" :ksbmbh="ksbmbh" :kmxzfs="kmxzfs" :isDialog="isDialog"
                @success="getList" :ksmc="ksmc" :tabDivShow="tabDivShow" :tableTool="tableTool" :fixedData="fixedData"
                :tablelist="tablelist" :filedList="filedList" :pageData="pageData" @init="getTool"
                @chooseList="chooseList" />

              <freeApply v-if="module==7" :ksbmbh="ksbmbh" :isDialog="isDialog" @success="getList" :ksmc="ksmc"
                :tablelist="tablelist" />
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗开始 -->
    <!-- 表格列数自定义 -->
    <b-modal id="tableSet" v-model="showmodal" centered title="表格自定义" title-class="font-18" size="lg" hide-footer>
      <div style="padding-left: 85px;">
        <el-transfer v-model="checkData" :data="allData" :titles="['所有列', '需要显示的列']"></el-transfer>
      </div>
      <div class="text-center mt-3">
        <button type="button" class="btn btn-info mr-2 w-sm h30" @click="getTool('1')">确定
        </button>
        <button type="button" class="btn btn-secondary w-sm h30" @click="showmodal = false"> 取消</button>
      </div>

    </b-modal>

    <!--批量审核弹窗 -->

    <changeExam :examDivShow="false" :ksbmbh="ksbmbh"></changeExam>

    <!-- 生成打印信息 -->
    <div class="container">
      <div id="pdf" style="display: none;">
        <div v-for="x in printList" :key="x.index" :id="'pdf'+x">
          <studentPrints :ksbmbh="ksbmbh" :bmh="x" ref="studentPrints" :examInfo="examInfo" />
        </div>
      </div>
      <div id="printId" style="display:none;"></div>
    </div>

  </Layout>
</template>
